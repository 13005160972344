.css-hoicef {
	display: none !important;
}

.css-kd3xim {
	padding: 0 20px;
}
.input-upload-image {
	display: none;
}
.main-btn-add {
	padding: 30px 0;
}
.btn-add-product {
	padding: 10px 20px;
	background-color: #1976d2;
	color: #fff;
}
